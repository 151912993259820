
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Activity</h5>
      <div class="card">
        <div class="card-body">
          <div class="pb-3">
          </div>
          <div class="row mb-2 gy-2">
            <div class="col-sm-12 col-md-6 d-flex">
              <div id="tickets-table_length" class="me-2 dataTables_length">
                <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
              <div class="dropdown no-arrow">
              <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ri ri-filter-3-fill"></i>
              </a>
              <ul class="dropdown-menu">
                <li><a @click="selectAllRows()" class="dropdown-item" href="javascript:void(0)"><i class="ri" :class="isSelectAll? 'ri-checkbox-blank-circle-line' : ' ri-checkbox-circle-line'" ></i> {{isSelectAll? 'DeSelect all' : 'Select all' }}</a></li>
                <li><a @click="confirmDelete()" class="dropdown-item" href="javascript:void(0)"><i class="ri ri-delete-bin-line"></i> Delete all</a></li>
              </ul>
            </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-inline text-sm-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table striped hover table-class="table table-centered w-100" thead-tr-class=""
               ref="selectableTable" :items="paginatedActivities"
               :fields="columns" responsive="sm">
                  <template v-slot:cell(check)="data">
                      <div class="form-check text-center m-auto">
                          <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input" :id="`tableSRadio${data.item.id}`" />
                          <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                      </div>
                  </template>
                  <template v-slot:cell(title)="data">
                    <div @click="navigateTo(data.item)" class="d-inline-block cursor-pointer">
                      <p class="mb-1">
                          <span  class="text-dark mr-2">{{ data.item.title }}</span>
                          <span v-if="!data.item.is_seen" class="badge badge-info">new</span>
                      </p>
                      <span @click="navigateTo(data.item)" class="text-dark small">{{ data.item.content }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(date)="data">
                      <span class="cursor-pointer" @click="navigateTo(data.item)">{{$filters.date_ago(data.item.created_at)}} </span>
                  </template>
            </b-table>
          </div>
          <div class="row">
              <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded">
                          <b-pagination v-model="paginate.currentPage" 
                          :total-rows="activities.length" :limit="paginate.limit" @page-click="onPageChange()" :per-page="paginate.perPage"></b-pagination>
                      </ul>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "activity-list",
  data() {
    return {
      paginate:{
        currentPage: 1,
        perPage: 10,
        startIndex: 0,
        endIndex: 10,
        limit:2
      },
      pageOptions: [10, 25, 50, 100],
      filter: '',
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: ""
          },
          {
            key: "title",
            label: "Title",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
      ]
    }
  },
  computed: {
    activities() {
      return this.$store.state.activity.list.filter((ac => ac.content.toLowerCase().includes(this.filter.toLowerCase())))
    },
    paginatedActivities(){
        return this.activities.slice(
            this.paginate.startIndex,
            this.paginate.endIndex
        );
    },
  },
  methods: {
    navigateTo(notif){
      if(!notif.is_read){
        this.$store.dispatch("activity/markAsRead", notif.id);
      }
      this.$router.push(notif.url).catch(() => {});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.activities.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchActivities() {
      this.$store.dispatch("activity/fetchActivities")
    },
    confirmDelete() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} activit${this.selected.length > 1 ? 'ies':'y' }`;
      Swal.fire({
        title: "Are you sure?",
        text: text,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/activities/delete',formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchActivities()
            }
          })
        }
      });
    },
    onPageChange() {
      this.paginate.startIndex = (this.paginate.currentPage - 1) * this.paginate.perPage;
      this.paginate.endIndex = (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  created(){
    this.$store.dispatch("activity/fetchActivities")
  },
}

</script>

